<template lang="pug">
  .home.fb
    .fb__container.container

      //- Титульная линия страницы
      .fb__top-line
        .fb__title-container.fb__title-container--row
          h1.fb__title {{ title }}
          el-tooltip(
            content="Дополнительная информация"
            placement="right"
            effect="light"
          )
            button.fb__tooltip.btn.btn--tooltip
              svg(width="16" height="16")
                use(xlink:href="@/assets/images/sprite/sprite.svg#icon-info")

      //- Текстовая заглушка
      .fb__body
        p.fb__text.fb__text--strong В 2021 году ФНС России полностью перейдет на предоставление сведений об адресах в муниципальном делении (ГАР)
        p.fb__text ФНС России является оператором (постановление Правительства Российской Федерации от 29.04.2014 № 384) Федеральной информационной адресной системы (ФИАС) и на еженедельной основе выгружает адресную информацию. Сейчас это происходит в двух форматах: старом - ФИАС, который содержит сведения об адресах в структуре административно-территориального деления, и в новом - Государственного адресного реестра (ГАР) с информацией об адресах в муниципальном делении. Последний утвержден приказом ФНС России от 13.05.2020 № ЕД-7-6/329
        p.fb__text В связи с необходимостью перехода на повсеместное использование (ч. 2 ст. 8 Федерального закона №443 от 28.12.2013) содержащихся в ГАР сведений об адресах в структуре муниципального деления, ФНС России прекратит предоставлять файлы выгрузки адресных сведений в формате ФИАС в августе 2021 года. Для ознакомления и подготовки информационных систем пользователей ФИАС к работе с новым форматом на портале (#[a.fb__text-link(href="https://fias.nalog.ru") https://fias.nalog.ru]) еженедельно размещаются файлы выгрузки адресной информации в формате ГАР.
</template>

<script>

export default {
  quantity: 'Main',

  data() {
    return {
      title: 'О ГАР',
    }
  },
}
</script>
